// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-page-js": () => import("./../src/pages/AboutPage.js" /* webpackChunkName: "component---src-pages-about-page-js" */),
  "component---src-pages-home-page-js": () => import("./../src/pages/HomePage.js" /* webpackChunkName: "component---src-pages-home-page-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-page-js": () => import("./../src/pages/ProjectsPage.js" /* webpackChunkName: "component---src-pages-projects-page-js" */)
}

